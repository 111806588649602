
import { defineComponent, onMounted, computed, ref } from 'vue';
import ApiService from '@/core/services/ApiService';
import TableWidget9 from '@/components/widgets/tables/Widget9.vue';
import TableWidget5 from '@/components/widgets/tables/Widget5.vue';
import ListWidget1 from '@/components/widgets/lists/Widget1.vue';
import ListWidget2 from '@/components/widgets/lists/Widget2.vue';
import ListWidget3 from '@/components/widgets/lists/Widget3.vue';
import ListWidget5 from '@/components/widgets/lists/Widget5.vue';
import ListWidget6 from '@/components/widgets/lists/Widget6.vue';
import MixedWidget2 from '@/components/widgets/mixed/Widget2.vue';
import MixedWidget5 from '@/components/widgets/mixed/Widget5.vue';
import MixedWidget7 from '@/components/widgets/mixed/Widget7.vue';
import MixedWidget10 from '@/components/widgets/mixed/Widget10.vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import SelectCustomerModal from '@/components/modals/forms/SelectCustomerModal.vue';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'cleanDashboard',
	components: {
		TableWidget9,
		TableWidget5,
		ListWidget1,
		ListWidget2,
		ListWidget3,
		ListWidget5,
		ListWidget6,
		MixedWidget2,
		MixedWidget5,
		MixedWidget7,
		MixedWidget10,
		SelectCustomerModal
	},
	setup() {
		const store = useStore();

		const { role } = store.getters.currentUser;
		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		const customerId = ref<any>({
			customerId: selectedCustomer
		});
		const syncMediaStatus = ref<any>(false);

		const showPopupModel = () => {
			if (!selectedCustomer || selectedCustomer.value === '') {
				const customerModel = document.getElementById(
					'kt_modal_select_customer'
				);
				if (customerModel) {
					customerModel.style.display = 'block';
					customerModel.classList.add('show');
				}
			}
		};

		onMounted(() => {
			setCurrentPageTitle('Dashboard');
			if (role.toLowerCase() === 'admin') {
				showPopupModel();
			}
		});

		const syncMedia = () => {
			ApiService.post(`Media/SyncMedia?customerId=${selectedCustomer.value}`, customerId.value)
				.then(({ data }) => {
					syncMediaStatus.value = data?.succes;
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		return {
			role,
			syncMedia,
			syncMediaStatus
		};
	}
});
