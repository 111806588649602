
import { defineComponent, ref, onMounted } from 'vue';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import ApiService from '@/core/services/ApiService';
import { ICustomer } from '@/core/data/customers';
import { Actions } from '@/store/enums/StoreEnums';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'edit-enumeration-modal',
	props: {
		// customerList: Array
	},
	components: {},
	setup() {
		const store = useStore();

		const selectCustomerModalRef = ref<null | HTMLElement>(null);
		const loading = ref<boolean>(false);
		const customerList = ref<Array<ICustomer>>([]);

		const getCustomersList = () => {
			ApiService.get('Customer')
				.then(({ data }) => {
					customerList.value.splice(0, customerList.value.length, ...data);
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const setCurrentCustomer = async id => {
			loading.value = true;
			store.dispatch(Actions.SWITCH_CUSTOMER, id);
			setTimeout(() => {
				const customerModel = document.getElementById(
					'kt_modal_select_customer'
				);
				if (customerModel) {
					customerModel.style.display = 'none';
					customerModel.classList.remove('show');
				}
				loading.value = false;
			}, 500);
		};

		onMounted(() => {
			getCustomersList();
		});

		return {
			loading,
			customerList,
			setCurrentCustomer,
			selectCustomerModalRef
		};
	}
});
